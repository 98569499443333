#badges {
  position: absolute;
  left: 18em;
}

.modal-body {
  p {
    max-width: 600px;
  }
}

.modal.revised.fade.show.m4c-matgen {
  z-index: 2147480000;
}

#options-bg {
  position: relative;
  top: -0.25em;
}

.env-badge {
  border: 1px solid #333;
  color: #fff;
  font-weight: bold;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  margin-top: 0.5em;
  font-size: 0.55em;
  text-transform: uppercase;
  position: relative;
  top: 0.35em;
}

#tenant-badge {
  background-color: #3b72a4;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-right: -5px;
  padding-left: 8px;
  /*padding-right: 8px;*/
}

#env-badge {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: 0;
  padding-right: 8px;
}

#matgen-controller {
  display: none;
}

#mfa-error-message {
  p {
    color: red;
  }
}

#matgen-mfa-setup-modal-label {
  line-height: 40px;
}

.alert-modal-close-button {
  background-image: none !important;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1em;
}

#alert-modal {
  p {
    max-width: 700px;
  }
  .alert {
    margin-top: 1em;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.alert {
  text-align: center;
}
.alert-danger {
  color: #fff;
  font-weight: bold;
}

.page-home {
  padding-bottom: 15em;
}
#matgen-header-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-dialog-scrollable.modal-dialog {
  /* Width */
  .modal-content {
    max-width: 85vw;
  }
}

.uploaded-image-group {
  margin-top: 2em;
}

#image-upload-form {
  max-width: 600px;
}

.upload-section {
  background: #ffffff;
  border: 0.9px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 2em;
  .upload-area {
    background: #f5f5f5;
    border: 1px dashed #939393;
    border-radius: 3px;
    width: 380px;
    height: 220px;
    margin: 0 auto;

    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      p {
        margin-bottom: 0;
      }
    }
  }
  .upload-link {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $purple;
    text-align: center;
    display: block;
    margin-top: 2em;
  }
}

#cropper-div {
  //max-height: 50vh;
  //max-width: 65vw;
  width: 600px;
  height: 400px;
}

#croppie-div {
  margin: auto;
}

.table-actions {
  white-space: nowrap;
  text-align: right;
}

.swatch {
  width: 50px;
  height: 2px;
  flex-grow: 1;
}

.btn {
  font-weight: bold;
}

#matgen-main {
  display: none;
  flex: 1;
  position: relative;
  display: flex;
  //flex-direction: ;
}

#options-container {
  min-width: 320px;
  //min-height: 220px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
}

.m4c-matgen.option-wrapper,
.option-wrapper {
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  background: url(/assets/img/editor-bg.png);
  position: relative;
  text-align: center;
  max-width: 30%;
  margin: 0.15em;
}

.admin-option-container {
  max-width: 20%;
  .option-wrapper {
    max-width: 100%;
  }
}

.option-wrapper:not(:first-of-type) {
  margin-top: 12px;
}

.option-wrapper:hover {
  border-color: var(--bs-secondary);
}

.option-wrapper:active,
.option-wrapper.selected-option {
  border-color: var(--bs-primary);
}

.option-wrapper input[type='checkbox'] {
  margin-right: 18px;
}

#error-container {
  //width: 48em;
  margin: auto;
  margin-top: 4em !important;
  text-align: center;
  max-width: 50vw;
  margin: auto;
}

#sidebar.user {
  //width: 200px;
  //min-width: 200px;
  .node-type,
  .node-icons {
    display: none;
  }
  .node-name {
    border: none;
    background: transparent;
  }
  .sidebar-actions {
    padding-left: 6px;
  }
}

.modal-dialog {
  max-width: fit-content;
}

.template-type {
  margin-bottom: 0.5em;
  font-size: 0.8em;
}

#page-thumb {
  max-width: 25vw;
}

.file-placeholder {
  text-align: center;
  color: var(--bs-primary);
}

#preview-col {
  display: flex;
  justify-content: center;
  max-width: 24em;
  margin: 4em auto;
  text-align: center;
  svg {
    color: var(--bs-primary);
  }
}

#finalize-form-section {
  //max-width:28em;
  //margin: auto;
}

.btn-outline-light {
  color: #a5a5a5;
}

.form-error {
  color: #{var(--bs-danger)};
  text-align: center;
}

#show-grid,
#hide-grid,
#slide-submenu {
  display: none;
}

main.editor {
  flex: 1;
  overflow: auto;
  position: relative;
  display: flex;
  // flex-direction: ;
  width: 100vw;
}

#matgen-controller {
  //display: flex;
  //align-items: center;
  //flex-direction: column;
  //width: 100%;
  height: 100%;
  width: 100%;
}

#matgen-controller.editor {
  width: 75%;
  // height: 90vh;
  overflow-y: scroll;
}

.node-name {
  text-transform: capitalize;
}

#question-material-name {
  display: flex;
  max-width: 30em;
}

#question-template-options {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
  align-self: flex-start;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 90%;
  div {
    svg {
      width: 35%;
      height: 35%;
    }
  }

  .template-option-wrapper {
    margin-top: $card-grid-top-offset;
    margin-left: 0.5em;
    right: 0.5em;
    width: 20em;
    display: flex;

    cursor: pointer;
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba($black, 0.1);

    .template-option-image {
      p {
        margin-bottom: 0;
      }
    }

    &:hover,
    &:focus {
      box-shadow: 0 6px 15px 0 rgba($black, 0.15);
    }

    .card-container {
      margin-top: 0;
      margin-left: 0;

      .card.option {
        background-color: transparent;
        border: none;
        border-radius: 3px;
        box-shadow: none;
        margin-bottom: 0;
        &:hover,
        &:focus {
          box-shadow: none;
        }

        h6 {
          margin: 1.6em 1em 0;
          color: #212121;
          font-size: 1em;
          font-weight: bold;
          line-height: 1.25;
        }
        p {
          margin: 0 1em 1.4em;
          font-size: 0.8em;
        }
      }
    }
  }

  .card-container {
    flex: 0 0 11.35em;
    max-width: 11.35em;
    margin-left: 0.45em;

    @include max(1464) {
      flex-basis: 10.35em;
    }

    .option {
      min-height: em(105px);
    }
  }

  .option-toggle {
    top: $card-grid-top-offset;
  }
}

#matgen-loader-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#matgen-loader-container.fit-container {
  position: absolute;
}

#matgen-loader-container.full-page {
  position: fixed;
  z-index: 2147483647;
}

#matgen-loader .overlay.loading:before {
  padding-top: 0 !important;
}

#progress-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483647;
}

.progress-bar {
  background-color: var(--progress-loader-color, #f00);
}

#alert-modal .modal-buttons {
  padding-top: 1em;
  text-align: center;
}

#alert-modal .modal-body {
  padding: 1em 2.5em;
}

#alert-modal .modal-dialog {
  //max-width: 45vw;
}

header {
  a {
    cursor: pointer;
    text-decoration: none;
    margin-right: 1.5em;
  }
  a:hover {
    text-decoration: underline;
  }
}

.page-home {
  header {
    border-top: 4px solid #bf1e2d;
    background: #ffffff;
    box-shadow: 0px 0px 9px #000000;

    a {
      color: #525252 !important;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      padding: 0 1em;

      &:hover,
      &:active {
        color: #0f75bc !important;
        cursor: pointer;
      }
    }
  }
  .hero {
    margin-top: 4em;

    .home-title-logo {
      width: 320px !important;
      margin-bottom: 1em;
    }

    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 84px;
      line-height: 75px;
      letter-spacing: -0.888889px;
      color: var(--bs-secondary, #777);
    }

    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 31px;
      color: #545454;
    }
    img {
      width: 100%;
    }
  }
  .how-it-works {
    margin-top: 2em;
    align-items: baseline;
    h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 75px;
      letter-spacing: -0.888889px;
      color: var(--bs-danger);

      @media only screen and (max-width: 991px) {
        text-align: center;
      }
    }
    .step {
      img {
        display: block;
        height: 7em;
        margin: 0 auto;
        margin-bottom: 2em;
      }
      p {
        text-align: center;
      }
      &::before {
        content: '';
        background-image: url('../../assets/img/dotted-arrow.svg');
        display: block;
        height: 20px;
        background-repeat: no-repeat;
        position: relative;
        left: 256px;
        top: 70px;

        @media only screen and (max-width: 1400px) {
          left: 205px;
        }
        @media only screen and (max-width: 1200px) {
          left: 172px;
        }
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }
    }
    .sq-step {
      &::before {
        background-image: url('../../assets/img/squiggle-arrow.svg');
      }
    }
    .step-4 {
      margin-top: 25px;
      @media only screen and (max-width: 991px) {
        margin-top: 0;
      }
    }
    .last-step {
      &:before {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }
  .btn-yellow {
    display: block;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    background-color: #ffb905;
    color: #000000;
    border-radius: 5px;
    padding: 0.75em 1.25em;
    margin: 4em 0;

    &:hover,
    &:active {
      background-color: #ffb905;
      -webkit-filter: brightness(70%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
    }

    @media only screen and (max-width: 991px) {
      margin: 2em auto;
      text-align: center;
    }
  }
  .about-section {
    .inner {
      margin: 4em auto;
      padding: 4em;
      background: #ffffff;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
      border-radius: 11px;
      max-width: 1039px;

      h2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 63px;
        text-align: center;
        letter-spacing: -0.888889px;
        color: #5a5a5a;
      }

      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 31px;
        text-align: center;
        color: #5a5a5a;
      }

      .banner {
        margin-top: 4em;
        background: #f1f1f1;
        border-radius: 7px;
        padding: 1em 0.75em;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          max-width: 180px;
        }
        p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 27px;
          color: #bf1e2d;
          margin-bottom: 0;
          @media only screen and (max-width: 991px) {
            margin-top: 2em;
          }
        }
      }
    }
  }
}

#page-loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2147483647;
  opacity: 0.8;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: -60px;
  margin-left: -50px;
  border-radius: 50%;
  border: 18px solid transparent;
  border-top-color: var(--spinner-color-1, #333);
  border-bottom-color: var(--spinner-color-2, #333);
  animation: spinner 2s ease infinite;
}

.overlay:before {
  background: var(--overlay-bg-color, #fff);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: var(--overlay-opacity, 0.8);
  position: absolute;
  color: var(--overlay-color, #333);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay.loading:before {
  content: attr(message);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  padding-top: var(--overlay-message-padding, 2.5em);
}

div.overlay.loading {
  display: block;
}

div.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2147483000;
  /*pointer-events: none;*/
  display: none;
}