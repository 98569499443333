// Media Query: min-width
@mixin min($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }

  @media screen and (min-width: #{$size}) {
    @content;
  }
}


// Media Query: max-width
@mixin max($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }

  @media screen and (max-width: #{$size}) {
    @content;
  }
}

// Media Query: min- and max-width in that order
@mixin minmax($min, $max) {
  @if (unitless($min)) {
    $min: $min * 1px;
  }

  @if (unitless($max)) {
    $max: $max * 1px;
  }

  @media screen and (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}

// PX to EM Default Size and Function
$base-font-size: 16;

@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return calc($pixels / $context * 1em);
}

// PX to em Function
@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return calc($pixels / $context * 1em)
}

// TODO: Expand this to take any type of unit value and convert to plain decimal.
@function line-height($pixels, $context: $base-font-size) {
  @return calc($pixels / $context * 1);
}

// Placeholder mixin for <input>
@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

// Clearfix
// TODO: @john As far as I can figure, this is used exactly once to create the class .clearfix. If it's
// used in like two more places then I'd say keep it but this is just unnecessary abstraction imo.
// If you disagree, delete this comment. If you agree, feel free to reify it or just ping me and
// I'll update it.
@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}
