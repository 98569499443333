.matgen-progress-bar {
  max-width: 720px;
  margin: 0 auto;

  #progressbar {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    padding: 0 2em;
    margin: 0;
    list-style: none;
  }

  .unit {
    flex: 1;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-top: em(14);
    text-align: center;

    .dot {
      position: absolute;
      display: inline-block;
      width: 1.75em;
      height: 1.75em;
      top: -8px;
      border-radius: 100%;
      border: 2px solid #a5a5a5;
      background-color: white;
      z-index: 1;
      color: #a5a5a5;
      font-weight: 700;
    }

    .line {
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      top: 5px;
      background-color: #a5a5a5;
      z-index: 0;

      .complete &,
      .active & {
        background-color: $blue;
      }

      &.first {
        left: 50%;
      }

      &.last {
        right: 50%;
      }

    }

    .title {
      position: relative;
      margin-top: 1em;
      top: 100%;
      color: rgba(0, 0, 0, 0.5);
      font-size: em(12);
      line-height: 1.6;
      transition: $transition;

      .active & {
        font-weight: 600;
      }
    }

    &.current-step {
      .dot {
        color: white;
        background: #{var(--bs-primary, #242424)};
        border-color: #{var(--bs-primary, #242424)};
      }
      .line {
        background: #{var(--bs-primary, #242424)};
      }
    }
  }
  .unitcomplete {
    flex: 1;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-top: em(14);
    text-align: center;

    .dot {
      position: absolute;
      display: inline-block;
      width: 1.75em;
      height: 1.75em;
      top: -8px;
      border-radius: 100%;
      border: 2px solid #a5a5a5;
      z-index: 1;
      font-weight: 700;
      color: white;
      background: #{var(--bs-primary)};
      border-color: #{var(--bs-primary)};
    }

    .line {
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      top: 5px;
      background-color: #{var(--bs-primary)};
      z-index: 0;


      &.first {
        left: 50%;
      }

      &.last {
        right: 50%;
      }

    }

    .title {
      position: relative;
      margin-top: 1em;
      top: 100%;
      color: rgba(0, 0, 0, 0.5);
      font-size: em(12);
      line-height: 1.6;
      transition: $transition;

      .active & {
        font-weight: 600;
      }
    }
  }
}
