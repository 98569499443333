body {
    color: $black;
    font-family: $roboto;
    font-size: 16px;
    line-height: 1.625;
  }
  
  a:not(.btn):not(.dropdown-item):not(#feedback-btn) {
    color: $black;
    text-decoration: underline;
    transition: $transition-fast;
  
    &:hover {
      color: $gray;
    }
  }
  
  @each $heading in $headings {
    #{$heading} {
      margin-top: 0;
      margin-bottom: 0.5em;
      color: $black;
      // TODO: See if this is needed
      // font-weight: bold;
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $roboto;
  }
  
  h1 {
    font-size: em(48);
  }
  
  h2 {
    font-size: em(40);
  }
  
  h3 {
    font-size: em(32);
  }
  
  h4 {
    font-size: em(26);
  }
  
  h5 {
    font-size: em(20);
  }
  
  h6 {
    font-size: em(16);
  }
  
  // NOTE: These are defined in the NIA Dementia Mat Gen Style Guide on InVision
  // https://projects.invisionapp.com/d/main#/console/19825099/415370232/inspect
  .body-copy {
    // NOTE: p3 in typography style tile
    &--sm {
      font-size: em(14);
      line-height: 1.375;
    }
  
    // NOTE: p2 in typography style tile
    &--xs {
      font-size: em(12);
      line-height: 1.25;
    }
  
    // NOTE: p1 in typography style tile
    &--xxs {
      font-size: em(10);
      line-height: 1;
    }
  
    // NOTE: p5 in typography style tile
    &--lg {
      font-size: em(18);
      line-height: 1.75;
    }
  
    // NOTE: p6 in typography style tile
    &--xl {
      font-size: em(20);
      line-height: line-height(32, 20);
    }
  }
  
  p,
  a {
    font-family: $roboto;
  }
  
  // NOTE: p (p4 in some places in Figma) in typography style tile
  p {
    color: $black;
    font-size: 1em;
    line-height: 1.625;
  
    & + p {
      margin-top: 1em;
    }
  }
  
  p + h2 {
    margin-top: 1.125em;
  }
  
  p + h4,
  ul + h4,
  ol + h4 {
    margin-top: 1em;
  }
  
  p + h2,
  ul + h2,
  ol + h2 {
    margin-top: 1em;
  }
  
  p + h3,
  ul + h3,
  ol + h3 {
    margin-top: 0.75em;
  }
  
  h2 + h3 {
    margin-top: -0.35em;
  }
  
  .bold {
    font-weight: bold;
  }