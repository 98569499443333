#sidebar {
  //display: flex;
  //flex-direction: column;
  //position: static;
  //left: 0.5em;
  //top: 0;
  //z-index: $sidebar-z-index;
  background: #fff;
  border-radius: 4px;
  //width: 300px;
  //min-width: 300px;
  //resize: horizontal;
  //overflow: scroll;
  flex-grow: 1;
  //width: 300px; /* viewport width */
  //height: 80vh; /* viewport height */
  //overflow-y: scroll;
  //overflow-x: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  .badge {
    font-size: 0.5em;
    margin-top: -0.25em;
    left: 0.1em;
    top: -1.25em;
    &.header {
      background: #fff;
      color: #{var(--bs-secondary)};
    }
  }
}

#matgen-sidebar {
  padding-right: 0.5em;
  width: 25%;
  height: 90vh;
  overflow-y: scroll;
}

.user-toggle-object.hide-btn {
  background: #fff;
  color: #{var(--bs-primary)};
  &:hover {
    background: #{var(--bs-secondary)};
    color: #fff;
  }
}

.node-toggle {
  display: none;
}

.dropdown-item {
  i,
  svg {
    margin-right: 0.5em;
  }

  .drop-delete {
    flex-grow: 1;
    text-align: right;
  }

  .drop-delete:hover {
    color: #fff !important;
  }
}

#sidebar-tree,
.read-order {
  position: relative;
  & > .tree-li {
    padding-left: 0;
    border: 1px solid #303f4829;
    border-top-width: initial;
    border-bottom-width: initial;
    margin-top: 0;
    cursor: pointer;

    &:first-child {
      border-radius: 0;
    }
  }
  & > .tree-li.list-group-item.active {
    background-color: transparent;
    border-color: #f00;
    color: #303f48;
    border-top-width: initial;
    border-bottom-width: initial;
    margin-top: 0;
  }
}

.read-order {
  margin-top: 6px;
  .node-content {
    padding: 12px 18px;
    padding-left: 9px;
    .node-grip {
      margin-right: 12px;
    }
  }
}

#font-variants-modal {
  .checkbox {
    display: inline-block;
  }
  .checkbox:not(:last-child) {
    margin-right: 0.5em;
  }
}

.list-group-item.active {
  background-color: transparent;
  border-color: #f00;
  color: #{var(--bs-secondary)};
  /*& > .node-content,
  & > .node-content .node-actions > a {
    background-color: #{var(--bs-primary)};
    color: #fff;
  }
  & > .node-content .node-icon,
  & > .node-content .open-btn,
  & > .node-content .close-btn {
    color: #fff;
  }
  & > .node-content .node-actions a .badge {
    background-color: #fff;
    color: #{var(--bs-primary)};
  }*/
}

.sortable-ghost.danger {
  background-color: #{var(--bs-danger)};
}

.list-group-item.danger {
  background-color: transparent;
  border-color: transparent;
  color: #{var(--bs-danger)};
  & > .node-content,
  & > .node-content .node-actions a {
    background-color: #{var(--bs-danger)};
    color: #fff;
  }
  & > .node-content .node-icon,
  & > .node-content .open-btn,
  & > .node-content .close-btn {
    color: #fff;
  }
  & > .node-content .node-actions a .badge {
    background-color: #fff;
    color: #{var(--bs-danger)};
  }
}

.layer-action {
  white-space: nowrap;

  .component-dirty {
    display: none;
  }
}

.badge-btn {
  display: flex;
  align-items: center;
  svg {
    display: inline-block;
    margin-right: 0.25em;
  }
}

.node-top,
.node-bottom {
  align-items: center;
  padding: 0.2em 0;
  //display: none;
}

.node-top {
  display: flex;
  border-bottom: 1px inset #0000000c;
  min-height: 2.5em;
}

.tree-li.active {
  .node-bottom {
    display: flex;
  }
}

.layer-action {
  //width: 1.5em;
  margin: 0 0.2em;
}

.node-label {
  font-size: 0.8em;
  flex-grow: 1;
}

.node-bottom {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  .node-actions {
    justify-content: flex-end;
  }
}

.node-type {
  width: 33%;
  display: flex;
  flex-direction: column;
  div {
    //height: 1em;
  }
}

.node-info {
  flex-grow: 1;
}

.node-icons {
  display: flex;
}

.node-content {
  padding: 0em 0.45em;
  display: flex;
  align-items: center;
  //cursor: pointer;
  .node-icon {
    margin-right: 0.5em;
    color: #{var(--bs-primary)};
    svg:not(:first-child) {
      margin-left: 0.5em;
    }
  }
  .node-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    font-size: 1.1em;

    button {
      //width: 1.65em;
      //height: 1.65em;
      white-space: nowrap;
      padding: 0.25em;
      font-size: 0.75em;
      line-height: 0.5;
      border-radius: 0.2em;
      margin: 0.1em 0.15em;
      min-width: 2em;
      svg:last-child {
        margin-left: 0;
      }
      .dirty {
        display: none;
      }
    }

    & > *:not(:first-child) {
      margin-left: 0.5em;
    }

    a {
      color: #{var(--bs-secondary)};
    }

    a:hover {
      color: #{var(--bs-primary)};
    }
  }
}

.tree-ul {
  list-style: none;
  padding: 0;
  color: #{var(--bs-secondary)};
  padding-left: 1em;
}

.tree-li {
  padding: 0;
  padding-left: 0.75em;

  .open-btn {
    display: none;
    margin-right: 0.25em;
    color: #{var(--bs-primary)};
    width: 0.5em;
  }
  .close-btn {
    display: none;
    margin-right: 0.25em;
    color: #{var(--bs-primary)};
    width: 0.5em;
  }
  .no-btn {
    width: 0.5em;
    display: none;
  }
  .node-grip {
    margin-right: 0.5em;
  }
  .node-icon {
    white-space: nowrap;
    min-width: 2em;
  }
  .node-name {
    font-size: 1.1em;
    word-break: break-word;
    width: 100%;
    padding: 0 0.25em;
    border: 1px solid #303f4822;
    background: #303f4805;
    margin: 0.35em 0;
    border-radius: 0.25em;
    text-transform: capitalize;
  }

  .badge-action {
    white-space: nowrap;
  }

  .tree-li.active > .node-content,
  & > .node-content:hover {
    //background: #{var(--bs-primary)};
    //color: #fff;
    //border: 2px dashed #{var(--bs-primary)};
    /*.open-btn,
    .close-btn {
      color: #fff;
    }

    .node-icon {
      color: #fff;
    }
*/
    .node-actions {
      svg {
        pointer-events: none;
      }
      & > a,
      & > .dropdown a {
        color: #{var(--bs-secondary)};
      }

      & > a:hover,
      & > .dropdown a.dropdown-item:hover {
        color: #fff;
      }
      & > .dropdown a:hover {
        color: #{var(--bs-primary)};
      }
      & a.layer-action:hover {
        color: #{var(--bs-primary)};
      }

      & > .badge-action {
        .badge {
          background-color: #{var(--bs-secondary)};
          color: #fff;
        }
      }

      & > .badge-action:hover {
        .badge {
          background-color: #{var(--bs-primary)};
          color: #fff;
        }
      }
    }
  }

  & > .node-content > .node-toggle > .no-btn {
    display: inline-block;
  }
}

.collapsibleList li {
  cursor: auto;
}

li.collapsibleListOpen {
  cursor: pointer;
  & > .node-content > .node-toggle > .open-btn {
    display: none;
  }
  & > .node-content > .node-toggle > .close-btn {
    display: inline-block;
  }
  & > .node-content > .node-toggle > .no-btn {
    display: none;
  }
}

li.collapsibleListClosed {
  cursor: pointer;
  & > .node-content > .node-toggle > .open-btn {
    display: inline-block;
  }
  & > .node-content > .node-toggle > .close-btn {
    display: none;
  }
  & > .node-content > .node-toggle > .no-btn {
    display: none;
  }
}

#sidebar-main {
  list-style-type: none;
  //padding: 0 8px;
  padding-right: 0.5em;
}

#sidebar-header,
#sidebar-associated-file-header {
  background-color: #{var(--bs-secondary)};
  border: 1px solid #{var(--bs-secondary)};
  color: #fff;
  white-space: nowrap;
  font-size: 1.5em;
  width: 100%;
  margin-left: 0;

  .dropdown-item {
    color: #{var(--bs-secondary)};
    padding: 0.25em 1em;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid #303f4822;
    }
  }
}

.sidebar-li {
  display: flex;
  flex: 1 auto;
  flex-direction: row;
  align-items: center;
  padding: 0.15em 0.5em;
  padding-right: 0;
  //cursor: pointer;
}

.sidebar-li:hover .sidebar-li.active {
  //background: #{var(--bs-primary)};
  //color: #fff;
  border-color: #f00;
}

.sidebar-actions {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: end;
  bottom: 0.75em;
  margin-right: 0.5em;
  flex-wrap: wrap;

  button {
    //width: 1.65em;
    //height: 1.65em;
    white-space: nowrap;
    //padding: 0.25em;
    font-size: 0.75em;
    line-height: 0.5;
    border-radius: 0.2em;
    margin: 0;
    margin-right: .25em;
    //min-width: 2em;
    svg:last-child {
      margin-left: 0;
    }
    .dirty {
      display: none;
    }
  }
}

#sidebar-associated-file-header {
  margin-top: 1.5em;
}

#sidebar-associated-file-header {
  background: #00a3ea;
  border: 1px solid #aaa;
  border-radius: 11px 11px 0px 0px;
  color: #fff;
  white-space: nowrap;
  font-size: 1.5em;
  width: 100%;
  margin-left: 0;
  padding: 0.15em 0.5em;
}

#sidebar.user {
  //width: 200px;
  //min-width: 200px;
  .node-type,
  .node-icon {
    display: none;
  }
  .node-name {
    border: none;
    background: transparent;
  }
  .sidebar-actions {
    padding-left: 6px;
  }
}

i.fa,
i.fas,
i.far {
  vertical-align: middle;
}

.mini-submenu {
  display: none;
  background-color: #{var(--bs-secondary)};
  border: 1px solid #{var(--bs-secondary)};
  color: #fff;
  border-radius: 4px;
  padding: 9px;
  /*position: relative;*/
  width: 42px;
}

.mini-submenu:hover {
  cursor: pointer;
  background-color: #{var(--bs-secondary-dark, #333)};
}

.mini-submenu .icon-bar {
  border-radius: 1px;
  display: block;
  height: 2px;
  width: 22px;
  margin-top: 3px;
}

.mini-submenu .icon-bar {
  background-color: #fff;
}

.submenu-button {
  background: rgba(0, 0, 0, 0.45);
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
}

#slide-submenu {
  padding: 0 8px;
  margin-right: 1em;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  margin: auto;
  width: 128px;
  height: 24px;
  top: -1.7em;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #{var(--bs-secondary)};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(5) {
  left: 80px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(6) {
  left: 104px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
