
  .save-notice {
    flex: 1 0 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .heading {
      font-size: em(26);
      font-weight: bold;
      letter-spacing: em(-0.45, 26);
      line-height: 1;
    }
    .copy {
      max-width: 36ch;
      color: $gray-a;
      font-size: em(20);
      font-weight: 400;
      line-height: line-height(26, 20);
      text-align: center;
    }
    .buttons {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
      .btn {
        padding-right: 3em !important;
        padding-left: 3em !important;

        &:first-child {
          margin-right: 1em;
        }

        &:last-child {
          margin-left: 0.35em;
        }

        &--filled {
          color: $white;
          background-color: $blue;

          &:visited {
            color: $white;
          }
        }
      }
    }
  }

.card-basic {
    padding: 1.25em;
    text-align: center;
    background-color: $white;
    border-radius: 3px;
  }
