#feedback-modal {
  .modal-content {
    width: 66% !important;
    margin: auto;
  }
}

.feedback-btn {
  position: fixed;
  width: fit-content;
  height: 40px;
  padding-right: 1em;
  padding-left: 1em;
  padding-top: 0.5em;
  top: 35%;
  right: 0;
  background-color: var(--feedback-btn-bg, #333);
  color: var(--feedback-btn-color, #fff);
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transform: rotate(-90deg);
  margin-right: -40px;
  border-radius: 10px 10px 0px 0px;
  transition: 0.5s;
  display: none;

  &:focus {
    outline: 2px solid #000;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95) rotate(-90deg);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1) rotate(-90deg);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95) rotate(-90deg);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  &.pulse {
    color: var(--feedback-btn-pulse-color);
    text-decoration: none;
    transform: scale(1.025) rotate(-90deg);

    background-color: var(--feedback-btn-pulse-bg);
    animation: pulse 2s infinite;
    &:hover {
      color: var(--feedback-btn-pulse-bg);
      background-color: var(--feedback-btn-pulse-color);
    }
  }

  &:hover {
    //color: white;
    text-decoration: none;
    transform: scale(1.15) rotate(-90deg);
  }
}

.feedback {
  &__container {
    padding-top: 4em;
    padding-bottom: 12em;

    .col-lg-12 {
      justify-content: center;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
    }

    p {
      font-size: em(14);
    }
  }

  &__heading {
    margin-bottom: 0.125em;
    font-size: 26px;
    font-weight: bold;
    color: #255dff;
    line-height: 30px;
  }

  &__subheading {
    margin-bottom: 0.75em;
    font-weight: bold;
    line-height: 21px;
  }

  &__section {
    margin: auto;
    margin-bottom: 0.75em;
    &__heading {
      margin-bottom: 0.15em;
      margin-top: 2em;
      font-weight: bold;
    }

    .feedback_ol {
      font-size: 0.875em;
      line-height: 1.625;
      margin-top: 0;
      margin-bottom: 1em;
    }
  }

  &-contact-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f1f3fa;
    padding: 2em;

    .g-recaptcha {
      margin-right: 2em;
    }

    label {
      display: block;
      text-align: left;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #454545;
      width: 100%;

      span {
        font-weight: 400;
      }
    }

    input[type='file'] {
      display: none;
    }

    .custom-file-upload {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      cursor: pointer;
      position: relative;
      visibility: visible;
      left: unset;
      width: 100%;
      height: 32px;
      margin-bottom: 1.3em;
      padding: 1.5em 0;
      color: #212121;
      font-size: 0.875em;
      line-height: 1.4285714286;
      border: 1px solid #e1e1e1;
      border-radius: 3px;

      p {
        padding-left: 1.15em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        margin-bottom: 0;
        color: #212121;
      }

      &:hover {
        span {
          background: #aaa;
        }
      }
      span {
        margin-left: auto;
        background: $gray-light;
        padding: 0 2em;
        width: 7em;
        height: 3em;
        display: flex;
        align-items: center;
        color: $gray-a;
      }
    }

    input,
    textarea {
      //flex: 1 1 100%;
      width: 100%;
      margin-bottom: 1.3em;
      padding: 0.5em 1.15em;
      color: $charcoal;
      font-size: em(14);
      line-height: line-height(20, 14);
      border: 1px solid $gray-light;
      border-radius: 3px;

      &::placeholder {
        color: $gray-a;
      }

      &:focus,
      &:active {
        border-color: $blue;
      }
    }

    /*input {
      &[type='submit'] {
        flex: 0 1 auto;
        width: auto;
        padding: 0.5em 2em;
        border-color: $blue;
        border-radius: 6em;
        color: $white;
        font-weight: 500;

        &:focus {
          outline: 1px dotted $blue !important;
        }
      }
    }*/
  }
}
