#m4c-body-wrapper {
  height: 100%;
  position: relative;
  z-index: 1;
}

#m4c-fullscreen-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
}

.m4c-fullscreen-loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2147483647;
}

.m4c-loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -140px;
  padding: 2em 4em;
}

.m4c-loader-content.active {
  background: var(--loader-content-bg, #fff);
  border-radius: 8px;
  border: 1px solid var(--loader-content-border, #999);
  box-shadow: 1px 1px 4px var(--loader-content-shadow, #999);
}

.m4c-fullscreen-loader-message {
  margin: 2em auto 0 auto;
  color: var(--loader-message-color, #000);
  &:empty {
    display: none;
  }
  p {
    margin: 0;
    padding: 0;
  }
}
