.step-wrapper {
    h1 {
      margin-top: 1.5em;
      font-size: 2.25em;
      color: #{var(--section-title-color)};
    }
    text-align: center;
    h2 {
        color: #{var(--bs-secondary)};
        font-size: 1.75em;
    }

    .title {
        color: #{var(--section-subtitle-color)};
        font-size: 1.25em;
    }
    .question:not(:first-of-type) {
      margin-top: 3em;
    }
    .question {
        max-width: 50em;
        display: block;
        margin: auto;
        margin-top: 2em;

        &.validate-error {
            transition-timing-function: ease-in-out;
            transition: 0.25s;
            border: 1px solid red;
            background: rgba(255, 0, 0, 0.10);
            max-width: 60em;
            padding: 1em 4em;
            margin: 1em auto;
            margin-top: 2em;
        }

        &.validate-error:not(:first-of-type) {
          margin-top: 3em;
        }

        .question-instructions {
          margin-bottom: 1.5em;
        }
        .single-select-form {
            display: flex;
            flex-wrap: wrap;
            margin-top: 2.9em;
                .answer {
                    width: 46%;
                    //flex-grow: 1;
                    display: block;
                    margin: auto;
                    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 10%);
                    text-align: left;
                    font-size: 1.25em;
                    font-weight: bold;
                    margin-bottom: 1.5em;
                    transition: all .2s ease-in-out;
                    text-decoration: none;

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.0125);
                    }

                    h2, p {
                        color: $gray-a;
                        margin: 0 auto;
                    }
                    h3 {
                        margin-bottom: 0;
                    }

                    input[type=checkbox], input[type=radio] {
                        display: none;
                     }

                     input:checked + label {
                        border: solid 1px #{var(--bs-primary)};
                        transform: scale(1.05);
                        background: $gray-light-b;

                        .image-wrapper {
                            background: $gray-light-a;
                        }

                        p {
                        color: #{var(--bs-primary)};
                        }
                     }

                    .answer-row {
                        display: flex;
                        align-items: center;
                        border-radius: 3px;
                        cursor: pointer;
                        background: white;
                        padding-right: .25em;
                        .image-wrapper {
                            display: inline-block;
                            background-color: $gray-light-b;
                            padding: 1.5em;
                            margin-right: 1em;
                            svg {
                                margin: 0 auto;
                                display: block;
                                width: 3em;
                                color: #{var(--bs-primary)};
                            }
                        }
                        .text-wrapper {
                            display: inline-block;
                            overflow: hidden;
                            max-height: 4.5em;
                        }
                    }
                }
            }
    .multi-select-form {
        padding: 2.5em;
        padding-bottom: 1.5em;
        padding-top: 2em;
        margin-bottom: 0.875em;
        text-align: center;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        box-shadow: 0 3px 4px 0 rgb(0 0 0 / 10%);

        input[type=checkbox], input[type=radio] {
            display: none;
         }



         .answer {
            display: inline-block;

            p {
                margin: 0;
                font-size: 16px;
            }

            label {
                display: block;
                margin: 0.25em 0.65em 1.35em;
                padding: 0.5em 1.5em;
                color: #454545;
                font-size: .75em;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1.6;
                text-align: center;
                text-decoration: none;
                border: 2px solid #{var(--bs-primary)};
                border-radius: 5em;
                outline: none;
                transition: .3s ease;
                background-color: #fff;
                cursor: pointer;

                &:hover {
                    background-color: rgb(#{var(--bs-primary-light)});
                    p {
                        color: #{var(--bs-secondary-light)};
                    }
                }
            }

            input:checked + label {
                background-color: #{var(--bs-primary)};
                p {
                    color: #fff;
                }
            }

         }
    }
    }
}

#matgen-controller {
   #matgen-button-div{
        text-align: center;
        .btn {
            text-decoration: none;
            padding:.65em 1.15em;
            color: white;
            border-radius: 30px;
            border: 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size:16px;
            letter-spacing: 0.75px;
            transition: all .2s ease-in-out;
            margin:1em;

            &:hover {
                cursor: pointer;
                transform: scale(1.025);
                //background-color: #{var(--bs-primary-light)};
            }
        }
    }
}
