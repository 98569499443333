@import 'overlay';
@import 'animations/dual-ring';
@import 'animations/ripple';
@import 'animations/dot-circle';
@import 'animations/roller';
@import 'animations/spinner';
@import 'animations/ring';
@import 'animations/ellipsis';
@import 'animations/bars';
@import 'animations/ball';
@import 'full-screen';

#m4c-loader-message {
  color: var(--bs-primary, #333) !important;
  background: var(--loader-message-bg-color, #333) !important;
  background: var(--loader-message-bg-gradient, #333) !important;
  border: 1px solid var(--loader-message-border-color, #333) !important;
  padding: .5em 1.25em;
  font-size: 1em;
  p {
    color: var(--bs-primary, #333) !important;
    font-size: 1em;
  }
}

.modal-backdrop {
  --bs-backdrop-bg: #fff;
}

.m4c-loader-anim {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m4c-loader-anim.m4c-spinner,
.m4c-loader-anim.m4c-roller {
  display: block;
}

.m4c-button-loader {
  position: relative;
}

.m4c-element-loader,
.m4c-button-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
