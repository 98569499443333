header {
    display: block;
    width: 100%;
    //background-color: $primary;
    padding:1.5em;
    margin-bottom:1em;
}

footer {
    width: 100%;

    .top-section {
        padding: 3em 1.5em;
        text-align: right;

        a {
            color: #{var(--bs-link-light)};
            text-decoration: none;
            transition: 300ms ease-in-out;

            &:hover {
                color: #{var(--bs-link-light-hover)};
            }
        }

        div {
            display: flex;
            display: inline-block;

            .social-links {
                margin-left: 1.5em;

                a {
                    padding: 0 0.25em;
                }
            }
        }
    }

    .bottom-section {
        padding: 3em 1.5em;
        text-align: center;
    }
}