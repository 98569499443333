//Base
@import 'base/mixins';
@import 'base/variables';
@import 'base/fonts';

//@import '../../node_modules/bootstrap/scss/bootstrap-reboot';
//@import '../../node_modules/bootstrap/scss/bootstrap';

:root {
  z-index: 1;
  --loader-overlay-bg-color: var(--bs-primary, #fff);
  --loader-overlay-opacity: .05;
  --loader-message-bg-color: rgb(247,247,249);
  --loader-message-bg-gradient: radial-gradient(circle, rgba(247,247,249,.7) 0%, rgba(255,255,255,0.2) 60%);
  --loader-message-border-color: rgba(247,247,249,.5);
  --loader-spinner-color-1: var(--bs-primary, #333);
  --loader-spinner-color-2: var(--bs-primary, #333);
  --loader-spinner-timing: 0.8s;
  --bs-backdrop-bg: #fff;
}

@import "loaders";

.grecaptcha-badge {
  visibility: hidden;
}

#image-upload-preview {
  margin-top: 1.25em;
}

#m4c-mfa {
  display: none;
}

#options-bg {
  display: flex;
  align-items: center;
  margin-left: 2em;
  margin: 0 auto;

  span {
    display: inline-block;
    width: 2em;
    height: 2em;
    border: 1px solid #00a19c;
    box-shadow: 0 px 0px 5px #999999;
    margin-right: 0.5em;
    font-size: 11px;
    cursor: pointer;
    border-radius: 0.25rem;

    &.checkered {
      background: url(/assets/img/editor-bg-light.png);
    }

    &.checkered-dark {
      background: url(/assets/img/editor-bg-dark.png);
    }

    &.active {
      border: 1px solid #dc322f;
    }
  }
}

/*
$primary: #0d6efd;
$secondary: #6c757d;
$danger: #dc3545;
$warning: #ffc107;
$info: #0dcaf0;
$success: #198754;
*/

//@import 'matgen-variables';

#m4c-matgen,
.m4c-matgen,
:is(.m4c-matgen) {
  @import 'base/typography';
  @import 'matgen';
  @import 'components/header';
  @import 'components/steps';
  @import 'components/progress-bar';
  @import 'components/sidebar';
  @import 'components/save-modal';
  @import 'components/feedback';
  @import 'components/logic-tagging';
  @import 'components/option-button';
}

html.m4c-matgen,
body.m4c-matgen {
  margin: 0;
  padding: 0;
  height: 100%;
}

#homeVideo {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}


.modal {
  textarea {
    height: 10rem;
  }
}
//@import 'matgen-variables';
